<template>
  <div class="discover-republishing-dashboard">
    <v-container fluid>
      <div class="d-flex flex-row mb-0">
        <h2 class="text-2xl mb-6">Republishing Discover</h2>
        <v-autocomplete
          v-model="selectedSiteHost"
          :items="sitesList"
          :menu-props="{ maxHeight: '400', zIndex: 13 }"
          label="Sélectionner un site"
          item-text="name"
          item-value="domain"
          @change="askForUpdateData"
          dense="dense"
          :useGetParams="true"
          class="select-department ml-16 shrink"
          outlined
        ></v-autocomplete>
      </div>
      <v-card>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="itemsTotal"
            :loading="loading"
            class="elevation-1"
            dense
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>GESTION</v-toolbar-title> -->
                <v-text-field
                  v-model="searchStr"
                  label="Recherche"
                  single-line
                  hide-details
                  class="v-search"
                  @input="askForUpdateData('searchStr')"
                ></v-text-field>
                <v-divider class="mx-4" inset vertical></v-divider>
                <!--v-spacer></v-spacer-->
                <v-row class="search-options">
                  <v-col cols="6">
                    <v-switch
                      v-model="searchInDiscover"
                      @change="askForUpdateData('searchInDiscover')"
                      label="Ne chercher que dans les posts ayant déjà été présents dans Discover"
                    ></v-switch>
                  </v-col>
                  <v-col v-if="searchInDiscover" cols="4">
                    <v-switch
                      v-model="notInDiscoverSinceDaysVisible"
                      @change="
                        askForUpdateData('notInDiscoverSinceDaysVisible')
                      "
                      label="N'est plus dans discover depuis"
                      inline
                    ></v-switch>
                  </v-col>
                  <v-col
                    v-if="searchInDiscover"
                    class="not-in-discover-since-days-col"
                    cols="1"
                  >
                    <v-text-field
                      v-if="searchInDiscover && notInDiscoverSinceDaysVisible"
                      v-model="notInDiscoverSinceDays"
                      @input="askForUpdateData('notInDiscoverSinceDays')"
                      class="not-in-discover-since-days-input"
                      label=""
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="searchInDiscover && notInDiscoverSinceDaysVisible"
                    class="not-in-discover-since-days-col not-in-discover-since-days-col-days"
                    cols="1"
                  >
                    jours
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:item.title="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <a :href="item.url">
                    <span
                      class="text--primary text-sm"
                      v-on="on"
                      v-bind="attrs"
                      v-html="truncate(item.title, 90, '...')"
                    ></span>
                  </a>
                </template>
                <span v-html="item.title"></span>
              </v-tooltip>
            </template>
            <template v-slot:item.created="{ item }">
              <div
                class="col-created"
                v-html="displayDatetime(item.created)"
              ></div>
            </template>
            <template v-slot:item.updated="{ item }">
              <div
                class="col-updated"
                v-html="displayDatetime(item.updated)"
              ></div>
            </template>
            <template v-slot:item.sessions_year="{ item }">
              <div
                class="col-sessions_year"
                v-html="prettyNumbers(item.sessions_year)"
              ></div>
            </template>
            <template v-slot:item.views_year="{ item }">
              <div
                class="col-views_year"
                v-html="prettyNumbers(item.views_year)"
              ></div>
            </template>
            <template v-slot:item.clicks_year="{ item }">
              <div
                class="col-clicks_year"
                v-html="prettyNumbers(item.clicks_year)"
              ></div>
            </template>
            <template v-slot:item.impressions_year="{ item }">
              <div
                class="col-impressions_year"
                v-html="prettyNumbers(item.impressions_year)"
              ></div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                @click="openDialogPostView(item.wp_post_id, item.url)"
                color="primary"
                class="ml-2"
                small
                row
                text
              >
                <v-icon color="primary">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data> No data </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card class="mt-2">
        <v-expansion-panels
          ><!-- v-model="panelDisplayOptions"-->
          <v-expansion-panel>
            <v-expansion-panel-header
              >Informations/Options d'affichage</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>
                Note: Les champs Sessions, Vues, Clicks et impressions sont un
                total pour l'année
              </p>
              <v-switch
                v-model="displayFullDatetime"
                label="Afficher les dates de création et de modification avec les heures, minutes et secondes"
                dense
              ></v-switch>
              <v-switch
                v-model="displayPrettyNumbers"
                label="Afficher les nombres plus lisibles"
                dense
              ></v-switch>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Informations supplémentaires</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <p>
                Rappel de ce qui est nécessaire pour un fonctionnement optimal
              </p>
              <pre>
☑️ Accès au WordPress 
☐ Installation/configuration du plugin "Reworld Media AI Tools" 
☐ Site whitelisté pour qu'il ait accès au javascript issu de ai-app.reworldmediafactory.com 
☐ L'éditeur de contenu riche doit être le "classique" et non le nouveau "Gutenberg"
☐ Le module "En bref" doit être implémenté en BO et en FO
☐ Site configuré pour avoir le postID à la fin de l'url 
  Exemple: https://www.mariefrance.fr/beaute/mon-article-123456.html
☐ Flux JSON des contenus par jour disponible et crawlé 
  Exemple: https://www.mariefrance.fr/feed/list/audience/(date)/20230101/(offset)/0/(limit)/10
☐ Site doit être présent dans RMR avec PropertyID GA4 + crawl sessions par article
☐ Accès au Discover du site dans GSC ( datareworldmedia@gmail.com ) + crawl
☐ Pour qu'un article soit présent dans le dashboard: il doit avoir plus de 100 sessions dans l'année écoulée (cette limite sera probablement bientôt enlevée)
☐ Configuration des options du plugin (prompte, enabled, ...)
Pour l'utilisation dans RMR: 
☐ Flux JSON du contenu par ID disponible
  Exemple: https://www.aufeminin.com/feed/content/audience/4082283
              </pre>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-container>
    <v-dialog v-model="dialogPostVisible" max-width="90%" max-height="90vh">
      <republishing-discover-post-modal-view
        :postId="selectedPostId"
        :postUrl="selectedPostUrl"
      ></republishing-discover-post-modal-view>
    </v-dialog>
  </div>
</template>
<script>
import { mdiPencil } from "@mdi/js";
import RepublishingDiscoverPostModalView from "@/components/google-discover/republishing-discover/modals/PostView.vue";
import axios from "@axios";

export default {
  name: "RepublishingDiscover",
  components: { RepublishingDiscoverPostModalView },
  data() {
    return {
      icons: {
        mdiPencil,
      },
      sitesList: null,
      selectedPostId: null,
      selectedPostUrl: null,
      dialogPostVisible: false,
      selectedSiteHost: null,
      searchStr: "",
      searchInDiscover: true,
      notInDiscoverSinceDaysVisible: true,
      notInDiscoverSinceDays: 30,
      displayFullDatetime: false,
      displayPrettyNumbers: true,
      timer: false,
      headers: [
        {
          text: "PostID",
          value: "wp_post_id",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Title",
          value: "title",
          align: "start",
          class: "pr-4",
          sortable: true,
        },
        //{ text: "URL", value: "url" },
        {
          text: "Création",
          value: "created",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Modification",
          value: "updated",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Last Discover",
          value: "last_seen_gd",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Sessions",
          value: "sessions_year",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Vues",
          value: "views_year",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Clicks",
          value: "clicks_year",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Impressions",
          value: "impressions_year",
          align: "end",
          class: "pr-4",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          class: "pr-4",
          sortable: false,
        },
      ],
      items: [],
      itemsTotal: 0,
      sortBy: ["sessions_year"],
      sortDesc: [true],
      options: {},
      loading: false,
    };
  },
  setup() {},
  async created() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "fullWidth");
    this.sitesList = await this.$store.dispatch("common/getSiteList");
    const urlParams = new URLSearchParams(window.location.search);
    const siteHostParam = urlParams.get("host");
    if (siteHostParam) {
      this.selectedSiteHost = siteHostParam;
    } else {
      this.selectedSiteHost = "www.aufeminin.com";
    }
    this.askForUpdateData("created");
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_APP_CONTENT_WIDTH", "boxed");
  },
  methods: {
    askForUpdateData(from) {
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.updateData();
          this.timer = null;
        }, 500);
      }
    },
    async updateData() {
      const url = "/google-discover/republishing-discover-search-rmr";
      const queryParams = {
        host: this.selectedSiteHost,
        search_str: this.searchStr,
        search_in_discover: this.searchInDiscover,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        sort_desc: this.options.sortDesc[0],
      };
      if (this.searchInDiscover && this.notInDiscoverSinceDaysVisible) {
        if (
          isNaN(this.notInDiscoverSinceDays) ||
          parseInt(this.notInDiscoverSinceDays) != this.notInDiscoverSinceDays
        ) {
          alert(
            'Veuillez remplir le champ "Nombre de jours" par un chiffre entier.'
          );
          return;
        }
        queryParams["days_since_gd"] = this.notInDiscoverSinceDays;
      }
      this.loading = true;
      const response = await axios.get(url, { params: queryParams });
      this.loading = false;
      this.items = response.data.items;
      this.itemsTotal = response.data.total;
    },
    openDialogPostView(postId, postUrl) {
      this.selectedPostId = postId;
      this.selectedPostUrl = postUrl;
      this.dialogPostVisible = true;
    },
    truncate(value, stop, clamp) {
      if (!value) return "";
      value = value.toString();
      value = value.replace(/&quot;/g, '"');
      clamp = stop < value.length && clamp !== "" ? clamp || "..." : "";
      return value.slice(0, stop) + clamp;
    },
    prettyNumbers(x, sep = " ") {
      if (this.displayPrettyNumbers) {
        if (!x) return x;
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
      }
      return x;
    },
    displayDatetime(dtStr) {
      if (!this.displayFullDatetime) {
        return dtStr.substring(0, 10);
      }
      return dtStr; //moment(dtStr).format("DD/MM/YYYY HH:mm:ss");
    },
  },
  watch: {
    options: {
      handler() {
        this.askForUpdateData();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.theme--light.v-tabs-items {
  background-color: #f4f5fa;
}

.discover-republishing-dashboard ::v-deep thead * {
  font-size: 0.9em !important;
}
.discover-republishing-dashboard ::v-deep tbody * {
  font-size: 0.9em !important;
}

.discover-republishing-dashboard ::v-deep tbody tr:nth-of-type(even) {
  background-color: rgba(60, 61, 117, 0.08);
}
.discover-republishing-dashboard ::v-deep a span.text--primary {
  color: #11396e !important;
  font-size: 1em !important;
}
</style>
